import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ComponentLoading } from '@components/Loading';

import { compareBy } from '@helper/functions/utility';

import {
  HeaderBtn,
  HeaderBtnText,
  StyledActionTd,
  StyledExpandableTable,
  StyledItemActionButton,
  StyledLoadingContainer,
  SortedTableTh as Th,
} from './style';
const { DateTime } = require('luxon');

const LoadingContainer = (props) => {
  return (
    <StyledLoadingContainer className="p-4 d-flex justify-content-center align-items-center">
      {props.children}
    </StyledLoadingContainer>
  );
};

type ItemSelectionHandler = (item: any) => void;

type ItemAction = {
  name: string;
  description?: string;
  icon?: any | JSX.Element;
  handler: ItemSelectionHandler;
};

type SortedTableProps = {
  title?: string;
  data: Record<string, any>[];
  headers: Record<string, any>[];
  hiddenKeys?: string[];
  itemActions?: ItemAction[];
  isLoading?: boolean;
  placeholder?: string | JSX.Element;
  cellAlignMiddle?: boolean;
  rowOnClick?: ItemSelectionHandler;
  tableType?: string;
};

export default function SortedTable({
  itemActions,
  isLoading,
  placeholder,
  title,
  data,
  headers,
  hiddenKeys = [],
  cellAlignMiddle = false,
  rowOnClick = undefined,
  tableType = '',
}: SortedTableProps) {
  // Set default values
  title = title ?? 'Table';
  data = data ?? [];
  headers = headers ?? [];
  hiddenKeys = hiddenKeys ?? [];

  // States
  const [sortBy, setSortBy] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [expandedItem, setExpandedItem] = useState('');

  const sortedItems = useMemo(() => {
    const tableHeaders = headers.length === 0 ? getDataHeaders(data[0] ?? {}) : headers;

    return sortData(data, tableHeaders, sortBy, sortDesc);
  }, [data, headers, sortBy, sortDesc]);

  // Event handlers
  const handleSetSort = useCallback((e) => {
    if (!e) {
      setSortBy('');
      return;
    }
    setSortBy(e);
  }, []);

  const handleSetSortDesc = useCallback((e) => {
    if (!e) {
      setSortDesc(false);
      return;
    }
    setSortDesc(true);
  }, []);

  const handleExpandItem = useCallback(
    (e) => {
      if (!e || e === expandedItem) {
        setExpandedItem('');
        return;
      }
      setExpandedItem(e);
    },
    [expandedItem],
  );

  // Collapse all expandable when data change
  useEffect(() => {
    setExpandedItem('');
  }, [data, headers, sortBy, sortDesc]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <ComponentLoading />
      </LoadingContainer>
    );
  }

  if (data.length === 0 || headers.length === 0) {
    return (
      <LoadingContainer>
        <h4>{placeholder ?? 'Sorry, we could not find any matching data.'}</h4>
      </LoadingContainer>
    );
  }

  return (
    <div className="nsw-table-responsive mb-4" role="table" aria-label={title} tabIndex={0}>
      <ExpandableTable className="nsw-table nsw-table--striped">
        <thead>
          <tr>
            <SortedTableHeaders
              headers={headers}
              hiddenKeys={hiddenKeys}
              sortBy={sortBy}
              setSortBy={handleSetSort}
              sortDesc={sortDesc}
              setSortDesc={handleSetSortDesc}
              itemActions={itemActions}
            />
          </tr>
        </thead>
        <tbody>
          <SortedTableRows
            items={sortedItems}
            headers={headers}
            hiddenKeys={hiddenKeys}
            itemActions={itemActions}
            expandedItem={expandedItem}
            expandItemHandler={handleExpandItem}
            cellAlignMiddle={cellAlignMiddle}
            rowOnClick={rowOnClick}
            tableType = {tableType}
          />
        </tbody>
      </ExpandableTable>
    </div>
  );
}

function getDataHeaders(item) {
  let tableHeaders = [];

  for (let key in item) {
    tableHeaders.push(key);
  }

  return tableHeaders;
}

function sortData(data, headings, sortBy, sortDesc) {
  if (!data || !headings || !sortBy) {
    return data;
  }

  const keys = headings.map((el) => el.key);

  if (!sortBy || !keys.includes(sortBy)) {
    return data;
  }

  let items = data;
  items.sort(compareBy(sortBy));

  if (sortDesc) {
    items = items.reverse();
  }

  return items;
}

function ExpandableTable(props) {
  return <StyledExpandableTable {...props} />;
}

function TableHeaderSortButton({ name, disabled, selected, sortDesc, clickAction }) {
  const SortIcon = () => {
    if (selected && sortDesc) {
      return <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon />;
  };

  if (disabled) {
    return <HeaderBtnText>{name}</HeaderBtnText>;
  }

  return (
    <HeaderBtn
      type="button"
      className={selected ? 'sort-btn-selected' : ''}
      onClick={clickAction}
      area-label={
        selected
          ? `Sorted by ${name} in ${sortDesc ? 'descending' : 'ascending'} order.`
          : `Sort by ${name}.`
      }
    >
      <HeaderBtnText>{name}</HeaderBtnText>
      <SortIcon />
    </HeaderBtn>
  );
}

function SortedTableHeaders({
  headers,
  hiddenKeys,
  sortBy,
  setSortBy,
  sortDesc,
  setSortDesc,
  itemActions,
}) {
  const setSort = (setBy) => {
    if (setBy === sortBy) {
      setSortDesc(!sortDesc);
    } else {
      setSortBy(setBy);
      setSortDesc(false);
    }
  };

  let tableHeaders = [];

  for (const h in headers) {
    const header = headers[h];

    if (hiddenKeys.includes(header.key)) {
      continue;
    }

    tableHeaders.push(
      <Th key={header.key}>
        <TableHeaderSortButton
          name={header.name}
          disabled={header.key === 'detailsCard' ? true : false}
          sortDesc={sortDesc}
          selected={header.key === sortBy ? true : false}
          clickAction={() => {
            setSort(header.key);
          }}
        />
      </Th>,
    );
  }

  for (const a in itemActions) {
    const action = itemActions[a];

    tableHeaders.push(
      <Th key={action.name}>
        <HeaderBtnText>{action.name}</HeaderBtnText>
      </Th>,
    );
  }

  return <>{tableHeaders}</>;
}

export const ItemActionButton = (props) => {
  const { onClick, className, ...others } = props;
  return (
    <StyledItemActionButton
      type="button"
      className={clsx({
        'nsw-button nsw-button--flex': 1,
        [className]: 1,
      })}
      onClick={onClick}
      {...others}
    />
  );
};

const renderCell = (content, contentType) => {
  // console.log(content);
  switch (contentType) {
    case 'datetime':
      return new Date(content).toLocaleString('en-AU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    case 'boolean':
      return content ? 'Yes' : 'No';
    default:
      return content;
  }
};

function SortedTableRows({
  items,
  headers,
  hiddenKeys,
  itemActions,
  expandedItem,
  expandItemHandler,
  cellAlignMiddle = false,
  tableType,
  rowOnClick = (e) => {},
}) {
  let tableRows = [];

  for (const i in items) {
    const item = items[i];
    let row = [];
    const rowKey = items['id'] ?? '' + Date.now() + i;

    const isExpanded = item['id'] === expandedItem;

    for (const h in headers) {
      const header = headers[h];

      if (hiddenKeys.includes(header.key)) {
        continue;
      }

      const content =
        header.key === 'detailsCard' ? (
          <ItemActionButton onClick={() => expandItemHandler(item['id'])}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ItemActionButton>
        ) : (
          item[header.key]
        );

      row.push(
        <td
          key={header.key}
          className={clsx({
            'tw-align-middle': cellAlignMiddle,
          })}
        >
          {header.prefix ?? ''}
          {renderCell(content, header?.type)}
          {header.suffix ?? ''}
        </td>,
      );
    }

    for (const a in itemActions) {
      const action = itemActions[a];

      if (tableType = 'outageAdminTable')
        {
          let expectedEndDate = row[4].props.children[1];
                let dateTimeVarExpected = DateTime.fromFormat(expectedEndDate, 'dd/MM/yyyy, hh:mm a');
                    let dateTimeVarCurrent = DateTime.now().setZone('Australia/Sydney');
                    if (dateTimeVarExpected > dateTimeVarCurrent)
                      {
                        row.push(
                          <StyledActionTd key={action.name}>
                            <ItemActionButton onClick={() => action.handler(item)}>
                              <action.icon />
                            </ItemActionButton>
                          </StyledActionTd>,
                        );
                      }
                      else {
                        row.push(
                          <StyledActionTd key={action.name}>
                          </StyledActionTd>,
                        );
                      }
        }
        else{
          row.push(
            <StyledActionTd key={action.name}>
              <ItemActionButton onClick={() => action.handler(item)}>
                <action.icon />
              </ItemActionButton>
            </StyledActionTd>,
          );
        }      
    }

    tableRows.push(
      <tr
        key={rowKey}
        onClick={() => {
          if (rowOnClick) {
            rowOnClick(item);
          }
        }}
        className={clsx({
          'tr-expanded': isExpanded,
          'hover:tw-bg-[var(--nsw-text-hover)] hover:tw-cursor-pointer': rowOnClick,
        })}
      >
        {row}
      </tr>,
    );

    if (isExpanded) {
      tableRows.push(<tr key={`${rowKey}-padding`}></tr>);

      const rowExpandable = item['detailsCard'] ? (
        <tr className={isExpanded ? 'tr-expanded-details' : ''} key={`${rowKey}-expandable`}>
          <td className="p-0" colSpan={headers.length + itemActions.length ?? 0}>
            {item['detailsCard']}
          </td>
        </tr>
      ) : (
        <tr></tr>
      );

      tableRows.push(rowExpandable);
    }
  } // End of for-loop

  return <>{tableRows}</>;
}
